<template>
	<div id="starbucks">
		<Header :navbg='true'></Header>
		<div class="section bigbg">
			<div class="title">小院咖啡服务</div>
			<div class="subtitle">给行程匆忙的你 一份心灵的慰藉</div>
			<div class="intr">
				小院咖啡，隶属于西安小院生活馆商业运营管理有限公司轻业态事业部核心业务， 主旨为打造以健康、绿色为主的轻食吧台服务平台，平台内包含小院精品咖啡服务产品线：小院咖啡、鲜榨果汁、原创甜品蛋糕以及健康轻食等，
				同时与头部咖啡品牌进行深度合作，将其优秀的产品线融入小院咖啡平台中，为顾客打造全方位高品质产品服务。
				同时小院咖啡平台除优质产品服务之外，拥有自主研发线上小程序，以及专业咖啡吧台运营与管理团队， 一方面为顾客提供贴心、智能的服务，另一方面为有建设咖啡吧、饮品吧需求的酒店、企事业单位等客户提供一站式运营服务。
			</div>
			<div class="btn">详情联系我们</div>
		</div>
		<div class="section van-swipe">
			<div class="title">小院生活咖啡服务门店</div>
			<van-swipe :autoplay="3000" indicator-color="white" class="banner" @change="starChange">
				<van-swipe-item v-for="(item,index) in starbucksList" :key="index">
					<van-image :src="item.image" fit="cover" class="image"/>
				</van-swipe-item>
			</van-swipe>
			<div class="">
				<div class="swipe-name">{{starbucksList[starCurrent].name}}</div>
				<div class="swipe-address">{{starbucksList[starCurrent].address}}</div>
				<div class="swipe-intr">{{starbucksList[starCurrent].intr}}</div>
			</div>
		</div>
		<div class="section van-swipe">
			<div class="title">小院生活自营精品生活馆</div>
			<van-swipe :autoplay="3000" indicator-color="white" class="banner" @change="selfChange">
				<van-swipe-item v-for="(item,index) in selfList" :key="index">
					<van-image :src="item.image" fit="cover" class="image"/>
				</van-swipe-item>
			</van-swipe>
			<div class="">
				<div class="swipe-name">{{selfList[selfCurrent].name}}</div>
				<div class="opentime">	<img src="../../assets/images/new/drinkicon2.png"> {{selfList[selfCurrent].address}}</div>
				<div class="opentime"><img src="../../assets/images/new/drinkicon1.png">{{selfList[selfCurrent].opentime}}</div>
				<div class="swipe-intr">了解更多></div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import utils from '@/common/utils'
	import Header from '@/components/head2022'
	import Footer from '@/components/foot2022'
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				starCurrent:0,
				selfCurrent:0,
				starbucksList: [{
						image: require("../../assets/images/new/drink1.png"),
						name: '小院生活·新华书店',
						address: '西安建筑科技大学草堂校区店',
						intr: '位于西安建筑科技大学草堂校区图书馆2F，总面积300平米，集咖啡饮品、图书借阅、特色文创、共享空间、精品小食等品类于一体，可以满足草堂校区师生的多样化需求。'
					},
					{
						image: require("../../assets/images/new/drink2.png"),
						name: '小院生活·正衡金融广场店',
						address: 'Y Cafe',
						intr: '位于西安市长安区雁塔南路正衡金融广场A栋1F，店面风格以灰白配色为主基调，简约大气，主要面向正衡金融广场及周边写字楼的消费者，可提供堂食或外送服务。'
					}
				],
				selfList: [{
						image: require("../../assets/images/new/shared7.png"),
						name: '小院图书馆·水岸花城店',
						address: '西安市泾渭新城泾渭街道泾渭五路1号',
						opentime: '9:00-18:00',
				
					},
					{
						image: require("../../assets/images/new/shared11.png"),
						name: '小院图书馆·奥韵华府店',
						address: '西安市高陵区昭慧路奥韵华府',
						opentime: '9:00-18:00'
					},
					{
						image: require("../../assets/images/new/drink3.png"),
						name: '小院生活·大雁塔店',
						address: '西安市曲江大雁塔音乐喷泉帛薇酒店大堂',
						opentime: '8:00-20:00'
					},
					{
						image: require("../../assets/images/new/drink4.png"),
						name: '小院生活·渭南宏帆广场店',
						address: '渭南市临渭区宏帆广场二楼',
						opentime: '10:00-22:00'
					},
					{
						image: require("../../assets/images/new/drink5.png"),
						name: '小院图书馆·经开未来城店',
						address: '渭南市临渭区新城二路与凤华街经开未来城',
						opentime: '10:00-22:00'
					},
				],
			}
		},
		methods: {
			starChange(e){
				this.starCurrent=e;
			},
			selfChange(e){
				this.selfCurrent=e;
			}
		}
	}
</script>

<style lang="scss">
	#starbucks {
		.title{
			font-size: 26px;
			font-weight: bold;
			line-height:26px;
			text-align: center;
		}
		.subtitle{
			font-size: 16px;
			line-height: 16px;
		}
		.intr{
			padding: 0 27px;
			font-size: 12px;
			line-height: 20px;
			font-weight: bold;
		}
		.btn {
			font-size: 12px;
			line-height: 32px;
			text-align: center;
			cursor: pointer;
			width:247px;
			height:32px;
			border: 1px solid #fff;
			border-radius:16px;
			margin: 0 auto;
		}
		.bigbg{
			background: url('../../assets/images/new/drinkbg.png') no-repeat;
			background-size: cover;
		}
		.section {
			padding: 106px 0 112px;
			box-sizing: border-box;
			width: 100%;
			height: 100vh;
			color: #FFFFFF;
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		.van-swipe{
			color:#000000 ;
			text-align: center;
			margin-top: 80px;
			.subtitle{
				margin:42px 0 33px;
				font-size:20px;
				font-weight: bold;
			}
			.swipe-address{
				font-size: 12px;
				line-height: 12px;
				font-weight: bold;
				color: #999999;
				margin: 6px 0 12px;
			}
			.swipe-intr{
				font-size: 12px;
				padding: 0 50px;
				line-height: 16px;
				
			}
			.swipe-name{
				font-size: 18px;
				line-height: 18px;
				font-weight: bold;
				margin-top: 20px;
			}
			.opentime{
				text-align: left;
				font-size: 16px;
				line-height: 16px;
				margin: 20px 0;
				
				img{
					width: 13px;
					margin-right: 9px;
				}
			}
		}
		.banner {
			width: 77.34%;
			height: 192px;
			margin: 0 auto;
			border-radius: 5px;
			.image {
				width: 100%;
			}
			:deep(.van-swipe__indicator) {
				width: 30px;
				height: 2px;
				border-radius: initial;
			}
		}
	}
</style>
